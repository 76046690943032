exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-charters-js": () => import("./../../../src/pages/charters.js" /* webpackChunkName: "component---src-pages-charters-js" */),
  "component---src-pages-contact-careers-js": () => import("./../../../src/pages/contact-careers.js" /* webpackChunkName: "component---src-pages-contact-careers-js" */),
  "component---src-pages-contact-charters-js": () => import("./../../../src/pages/contact-charters.js" /* webpackChunkName: "component---src-pages-contact-charters-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-license-js": () => import("./../../../src/pages/contact-license.js" /* webpackChunkName: "component---src-pages-contact-license-js" */),
  "component---src-pages-contact-lost-property-js": () => import("./../../../src/pages/contact-lost-property.js" /* webpackChunkName: "component---src-pages-contact-lost-property-js" */),
  "component---src-pages-driver-training-licensing-js": () => import("./../../../src/pages/driver-training-licensing.js" /* webpackChunkName: "component---src-pages-driver-training-licensing-js" */),
  "component---src-pages-how-quiz-js": () => import("./../../../src/pages/how-quiz.js" /* webpackChunkName: "component---src-pages-how-quiz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lost-property-js": () => import("./../../../src/pages/lost-property.js" /* webpackChunkName: "component---src-pages-lost-property-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-public-transport-js": () => import("./../../../src/pages/public-transport.js" /* webpackChunkName: "component---src-pages-public-transport-js" */),
  "component---src-pages-school-bus-js": () => import("./../../../src/pages/school-bus.js" /* webpackChunkName: "component---src-pages-school-bus-js" */),
  "component---src-pages-things-to-do-in-auckland-js": () => import("./../../../src/pages/things-to-do-in-auckland.js" /* webpackChunkName: "component---src-pages-things-to-do-in-auckland-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

